<template>
  <v-container
    class="pa-0"
    style="background-color: white; height: 100%;"
  >
    <v-card
      height="100%"
      flat
    >
      <v-toolbar
        flat
        class="sticky-title"
      >
        <v-btn
          icon
          rounded
          @click.stop="$router.back()"
        >
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('actions|add_document') }}</v-toolbar-title>
      </v-toolbar>
      <div v-if="!emitedClient">
        <v-stepper
          v-model="e6"
          vertical
          flat
        >
          <v-stepper-step
            :complete="e6 > 1"
            step="1"
          >
            {{ $t('actions|add_to') }}
            <small
              v-if="selectedDocumentsSection"
              class="mt-1"
            >
              {{ selectedDocumentsSection }}
            </small>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-select
              v-model="selectedDocumentsSection"
              :items="availableDocumentsSections"
              item-text="text"
              item-value="text"
              item-disabled="disabled"
              outlined
              dense
              :label="$t('documents|the_document_is_intended_for')"
              class="my-2"
              @change="e6 = 2"
            />
          </v-stepper-content>

          <v-stepper-step
            :complete="e6 > 2"
            step="2"
          >
            {{ $t('actions|upload_documents') }}
          </v-stepper-step>

          <v-stepper-content step="2">
            <file-input
              v-model="innerDocuments"
              class="mt-6 mx-6"
              file-key="raw"
              @delete="deleteFile"
            />
            <v-btn
              text
              class="mt-3 ml-2"
              @click="e6--; selectedDocumentsSection = null"
            >
              <v-icon class="mr-1">
                mdi-undo
              </v-icon>
              {{ $t('actions|back') }}
            </v-btn>
          </v-stepper-content>
        </v-stepper>
      </div>

      <div v-else>
        <file-input
          v-model="innerDocuments"
          class="mt-6 mx-6"
          file-key="raw"
          @languageChange="updateInnerDocuments"
          @delete="deleteFile"
        />
      </div>
    </v-card>

    <v-toolbar
      v-if="e6 === 2 || emitedClient"
      flat
      dense
      style="position: sticky; bottom: 0;"
    >
      <v-spacer />
      <v-btn
        v-if="innerDocuments.length"
        :key="buttonComponentKey"
        small
        color="primary"
        rounded
        :loading="loading"
        :disabled="isLanguageSelected(innerDocuments)"
        class="ma-4"
        min-width="150"
        @click="uploadDocumentAction"
      >
        {{ $t('actions|upload') }}
      </v-btn>
      <v-spacer />
    </v-toolbar>
  </v-container>
</template>

<script>
import { userHasAccess } from '@/utils/utils'
import { mapActions, mapState } from 'vuex'
import generalMixin from '@/utils/generalMixin.js'

export default {
  mixins: [generalMixin],
  data () {
    return {
      dialog: false,
      loading: false,
      innerDocuments: [],
      fileSizeInfo: '',
      emitedClient: null,
      emitedTab: {},
      e6: 1,
      selectedDocumentsSection: null,
      saveTo: '',
      hoverCloseButtonIcon: false,
      activeFolderObject: null,
      buttonComponentKey: 1
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company
    }),
    availableDocumentsSections () {
      let sections = []

      const getAccess = (_acc, _comp, _group, _tab, _from) => {
        return userHasAccess(_acc, _comp, _group, _tab, _from)
      }
      const accountAccess = getAccess(this.account, null, null, null, 'onlyAccountAccess')
      const companyAccess = getAccess(this.account, this.company, null, null, 'availableSections')

      if (this.account && !this.account.companyId) {
        sections.push({
          text: this.$t('documents|my_documents'),
          disabled: !accountAccess
        })
      }
      if (this.account && this.account.companyId && this.company) {
        const userRole = this.verifyUserRole(this.company, this.account)
        // verifyUserRole could be found in generalMixin
        if (userRole === 'regular') {
          sections.push({
            text: this.company.companyData.companyName + ' ' + this.$t('documents|documents'),
            disabled: !companyAccess
          })
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            const participation = group.groupMembers.find(m => m._id === this.account._id)
            if (participation) {
              sections.push({
                text: group.groupName + ' ' + this.$t('documents|documents'),
                disabled: !companyAccess
              })
            }
          }
        } else {
          sections.push({
            text: this.$t('documents|my_documents'),
            disabled: !accountAccess
          })
          sections.push({
            text: this.company.companyData.companyName + ' ' + this.$t('documents|documents'),
            disabled: !companyAccess
          })
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            const participation = group.groupMembers.find(m => m._id === this.account._id)
            if (participation) {
              sections.push({
                text: group.groupName + ' ' + this.$t('documents|documents'),
                disabled: !companyAccess
              })
            }
          }
        }
      }
      return sections
    }
  },
  watch: {
    dialog (value) {
      if (!value) {
        this.reset()
      }
    },
    selectedDocumentsSection (value) {
      if (value) {
        if (value === this.$t('documents|my_documents')) {
          this.emitedTab = {
            isMyTab: true
          }
        }
        if (this.company && value === this.company.companyData.companyName + ' ' + this.$t('documents|documents')) {
          this.emitedTab.isCompanyTab = true
        }
        if (this.company && value !== this.$t('documents|my_documents') && value !== this.company.companyData.companyName + ' ' + this.$t('documents|documents')) {
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            const extendedGroupName = group.groupName + ' ' + this.$t('documents|documents')
            if (extendedGroupName === value) {
              this.emitedTab.isGroupTab = true
              this.emitedTab.group = group
            }
          }
        }
      }
    }
  },
  mounted () {
    if (Object.keys(this.$route.params).length) {
      for (const key in this.$route.params) {
        if (Object.hasOwnProperty.call(this.$route.params, key)) {
          const obj = this.$route.params[key]
          if (key && key === 'client') {
            this.onAddDocumentEvent(obj)
          }
        }
      }
    }
  },
  methods: {
    ...mapActions({
      uploadDocument: 'documents/uploadDocument',
      uploadGroupDocument: 'groupDocuments/uploadGroupDocument',
      uploadCompanyDocument: 'companyDocuments/uploadCompanyDocument',
      updateDocument: 'documents/updateDocument'
    }),
    updateInnerDocuments (docs) {
      this.innerDocuments = docs
      this.buttonComponentKey++
    },
    isLanguageSelected () {
      let rule = true
      let _array = []
      if (this.innerDocuments && this.innerDocuments.length) {
        for (let i = 0; i < this.innerDocuments.length; i++) {
          const doc = this.innerDocuments[i]
          if (!doc.language.length) _array.push('empty')
          else _array.push(doc.language)
        }
        if (_array.indexOf('empty') !== -1) rule = true
        else rule = false
      }
      return rule
    },
    onAddDocumentEvent (client) {
      if (client && Object.keys(client).length) {
        let groupIndex
        let companyIndex
        const index = client.sharedWith.accounts.findIndex(acc => acc === this.account._id)
        if (this.company) {
          companyIndex = client.sharedWith.company.findIndex(c => c === this.company._id)
          this.company.groups.forEach(group => {
            groupIndex = client.sharedWith.groups.findIndex(g => g === group._id)
            if (groupIndex !== -1) {
              this.selectedDocumentsSection = group.groupName + ' ' + this.$t('documents|documents')
              this.emitedTab.group = group
              this.emitedTab.isGroupTab = true
              this.saveTo = 'Group'
            }
          })
        }
        if (index !== -1) {
          this.selectedDocumentsSection = this.$t('documents|my_documents')
          this.emitedTab.isMyTab = true
          this.saveTo = 'Account'
        } else if (companyIndex !== -1) {
          this.selectedDocumentsSection = this.company.companyData.companyName + ' ' + this.$t('documents|documents')
          this.emitedTab.isCompanyTab = true
          this.saveTo = 'Company'
        }
        this.emitedClient = client
      }
    },
    onAddDocumentEventFromTable (tab, activeFolderObject) {
      this.activeFolderObject = activeFolderObject
      if (tab.isCompanyTab) {
        this.selectedDocumentsSection = tab.company.companyData.companyName + ' ' + this.$t('documents|documents')
        this.e6 = 2
      }
      if (tab.isGroupTab) {
        this.selectedDocumentsSection = tab.group.groupName + ' ' + this.$t('documents|documents')
        this.e6 = 2
      }
      if (tab.isMyTab) {
        this.selectedDocumentsSection = this.$t('documents|my_documents')
        this.e6 = 2
      }
      this.dialog = true
      this.emitedTab = tab
    },
    async uploadDocumentAction () {
      this.loading = true
      for (let i = 0; i < this.innerDocuments.length; i++) {
        const document = this.innerDocuments[i]
        let payload = {}
        if (this.emitedTab.isMyTab && !this.emitedClient) {
          const _id = this.account._id
          payload = {
            language: document.language,
            clients: this.account.accountType === 'lawyer' ? [] : [this.account.clientAccount],
            file: document.file,
            isMyTab: true,
            activeFolderId: this.activeFolderObject ? this.activeFolderObject._id : null
          }
          await this.uploadDocument({ _id, payload })
        }
        if (!this.emitedTab.isSingleAcc && this.emitedTab.isCompanyTab && !this.emitedClient) {
          const _id = this.emitedTab.company._id
          payload = {
            language: document.language,
            clients: [],
            file: document.file,
            isCompanyTab: this.emitedTab.isCompanyTab,
            activeFolderId: this.activeFolderObject ? this.activeFolderObject._id : null
          }
          await this.uploadCompanyDocument({ _id, payload })
        }
        if (!this.emitedTab.isSingleAcc && this.emitedTab.isGroupTab && !this.emitedClient) {
          const _id = this.emitedTab.group._id
          payload = {
            language: document.language,
            clients: [],
            file: document.file,
            isGroupTab: this.emitedTab.isGroupTab,
            groupId: this.emitedTab.group._id,
            activeFolderId: this.activeFolderObject ? this.activeFolderObject._id : null
          }
          await this.uploadGroupDocument({ _id, payload })
        }
        if (this.emitedClient) {
          let _id
          let groupId
          if (this.emitedTab.isMyTab) {
            _id = this.account._id
          } else if (this.emitedTab.isCompanyTab) {
            _id = this.company._id
          } else if (this.emitedTab.isGroupTab) {
            groupId = this.emitedTab.group._id
            _id = this.emitedTab.group._id
          }
          payload = {
            language: document.language,
            clients: this.emitedClient._id || [],
            file: document.file,
            sharedWith: this.saveTo === 'Group' ? groupId : _id,
            saveTo: this.saveTo,
            attachClientDocument: true
          }
          if (this.emitedTab.isMyTab) {
            await this.uploadDocument({ _id, payload })
          } else if (this.emitedTab.isCompanyTab) {
            await this.uploadCompanyDocument({ _id, payload })
          } else if (this.emitedTab.isGroupTab) {
            await this.uploadGroupDocument({ _id, payload })
          }
        }
      }
      this.loading = false
      this.$router.back()
    },
    deleteFile (document) {
      console.error(document, 'to be implemented')
    },
    reset () {
      this.innerDocuments = []
      this.fileSizeInfo = ''
      this.emitedClient = null
      this.emitedTab = {}
      this.selectedDocumentsSection = null
      this.e6 = 1
      this.loading = false
      this.selectedDocumentsSection = null
      this.saveTo = ''
      this.hoverCloseButtonIcon = false
    }
  }
}
</script>

<style scoped>
.sticky-title {
  position: sticky;
  top: 48px;
  z-index: 1;
  background-color: white;
}
</style>
